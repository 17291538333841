import React from "react";

const Disclaimer = () =>{

    return(
        <div>
            <h1>This site is purely for educational purposes. All images and content are the property of their respective creators. We do not claim ownership or copyright of any of the artwork featured on this site. We strive to ensure that all artwork displayed here complies with copyright laws and respects the intellectual property rights of others. However, if you believe that any content on this site violates your copyright, please notify us at gentech.emory@gmail.com so that we can take immediate action. Thank you for visiting Virtuoso. This link will take you back <a href = '/'>home</a>.</h1>
        </div>
    )
}
export default Disclaimer; 